<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formCoupon" @submit.prevent="submit">
          <div class="row">
            <div class="col-12 d-flex justify-content-center gap-3 mb-7">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.couponImageAr')"
                :rules="editMode ? '' : 'required'"
                class="d-block"
                style="width: 220px; height: 200px"
              >
                <div class="input-title mb-3 text-center">
                  <h4>{{ $t("labels.couponImageAr") }}</h4>
                </div>
                <!-- @change="sentImage($event)" -->
                <v-file-input
                  @change="uploadImage($event, 'coupons', 'avatar')"
                  v-model="data.avatar.mainMedia"
                  class="d-none"
                  :name="$t('labels.couponImageAr')"
                  accept="image/*,|size:2048"
                  label=""
                  id="banner-create-image-main-ar"
                />

                <label
                  :class="errors[0] ? 'errors' : ''"
                  for="banner-create-image-main-ar"
                  class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
                >
                  <v-img
                    v-if="data.avatar.preview"
                    class="d-flex"
                    cover
                    :src="data.avatar.preview"
                    :aspect-ratio="3.4"
                  />
                  <img
                    v-else
                    class="d-flex img-fluid mx-auto"
                    src="@/assets/media/images/placeholder.png"
                  />

                  <span class="error--text d-block">
                    {{ errors[0] }}
                  </span>
                </label>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.couponImageEn')"
                :rules="editMode ? '' : 'required'"
                class="d-block"
                style="width: 220px; height: 200px"
              >
                <div class="input-title mb-3 text-center">
                  <h4>{{ $t("labels.couponImageEn") }}</h4>
                </div>
                <!-- @change="sentImage($event)" -->
                <v-file-input
                  @change="uploadImage($event, 'coupons', 'avatarEn')"
                  v-model="data.avatarEn.mainMedia"
                  class="d-none"
                  :name="$t('labels.couponImageEn')"
                  accept="image/*,|size:2048"
                  label=""
                  id="banner-create-image-main-en"
                />

                <label
                  :class="errors[0] ? 'errors' : ''"
                  for="banner-create-image-main-en"
                  class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
                >
                  <v-img
                    v-if="data.avatarEn.preview"
                    class="d-flex"
                    cover
                    :src="data.avatarEn.preview"
                    :aspect-ratio="3.4"
                  />
                  <img
                    v-else
                    class="d-flex img-fluid mx-auto"
                    src="@/assets/media/images/placeholder.png"
                  />

                  <span class="error--text d-block">
                    {{ errors[0] }}
                  </span>
                </label>
              </validation-provider>

              <template>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.couponInnerImageAr')"
                  :rules="editMode ? '' : 'required'"
                  class="d-block"
                  style="width: 220px; height: 200px"
                >
                  <div class="input-title mb-3 text-center">
                    <h4>{{ $t("labels.couponInnerImageAr") }}</h4>
                  </div>
                  <!-- @change="sentImage($event)" -->
                  <v-file-input
                    @change="uploadImage($event, 'coupons', 'inner_media_ar')"
                    v-model="data.inner_media_ar.mainMedia"
                    class="d-none"
                    :name="$t('labels.couponInnerImageAr')"
                    accept="image/*,|size:2048"
                    label=""
                    id="banner-create-image-main-inner_media_ar"
                  />

                  <label
                    :class="errors[0] ? 'errors' : ''"
                    for="banner-create-image-main-inner_media_ar"
                    class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
                  >
                    <v-img
                      v-if="data.inner_media_ar.preview"
                      class="d-flex"
                      cover
                      :src="data.inner_media_ar.preview"
                      :aspect-ratio="3.4"
                    />
                    <img
                      v-else
                      class="d-flex img-fluid mx-auto"
                      src="@/assets/media/images/placeholder.png"
                    />

                    <span class="error--text d-block">
                      {{ errors[0] }}
                    </span>
                  </label>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('labels.couponInnerImageEn')"
                  :rules="editMode ? '' : 'required'"
                  class="d-block"
                  style="width: 220px; height: 200px"
                >
                  <div class="input-title mb-3 text-center">
                    <h4>{{ $t("labels.couponInnerImageEn") }}</h4>
                  </div>
                  <!-- @change="sentImage($event)" -->
                  <v-file-input
                    @change="uploadImage($event, 'coupons', 'inner_media_en')"
                    v-model="data.inner_media_en.mainMedia"
                    class="d-none"
                    :name="$t('labels.couponInnerImageEn')"
                    accept="image/*,|size:2048"
                    label=""
                    id="banner-create-image-main-inner_media_en"
                  />

                  <label
                    :class="errors[0] ? 'errors' : ''"
                    for="banner-create-image-main-inner_media_en"
                    class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
                  >
                    <v-img
                      v-if="data.inner_media_en.preview"
                      class="d-flex"
                      cover
                      :src="data.inner_media_en.preview"
                      :aspect-ratio="3.4"
                    />
                    <img
                      v-else
                      class="d-flex img-fluid mx-auto"
                      src="@/assets/media/images/placeholder.png"
                    />

                    <span class="error--text d-block">
                      {{ errors[0] }}
                    </span>
                  </label>
                </validation-provider>
              </template>
            </div>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.journeyDiscount')"
              class="col-lg-4"
              rules="required|min_value:0|"
            >
              <base-input
                name="discountAppliedToJourney"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.discountAppliedToJourney')"
                v-model="data.discountAppliedToJourney"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.nameAr')"
              class="col-md-6 col-lg-4"
              rules="required"
            >
              <base-input
                :classes="errors[0] ? 'error' : ''"
                v-model="data.nameAr"
                :placeholder="$t('labels.nameAr')"
                type="text"
                :name="$t('labels.nameAr')"
              ></base-input>

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.nameEn')"
              class="col-md-6 col-lg-4"
              rules="required"
            >
              <base-input
                :classes="errors[0] ? 'error' : ''"
                v-model="data.nameEn"
                :placeholder="$t('labels.nameEn')"
                type="text"
                :name="$t('labels.namEn')"
              ></base-input>

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.desc') + $t('inArabic')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isArabic"
                :name="$t('labels.desc') + $t('inArabic')"
                type="textarea"
                :placeholder="$t('labels.desc') + $t('inArabic')"
                v-model="data.descAr"
              />
              <span class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.desc') + $t('inEnglish')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isEnglish"
                type="textarea"
                :name="$t('labels.desc') + $t('inEnglish')"
                :placeholder="$t('labels.desc') + $t('inEnglish')"
                v-model="data.descEn"
              />
              <span class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- Start:: code -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.code')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-input
                :name="$t('labels.code')"
                type="text"
                :placeholder="$t('labels.code')"
                v-model="data.code"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: code -->

            <!-- Start:: discount_type -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.discount_type')"
              class="col-lg-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.discount_type')"
                v-model="data.discount_type"
                :static="true"
                :staticItems="discountTypes"
                :placeholder="$t('labels.discount_type')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: discount_type -->

            <!-- Start:: DiscountAmount -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type ? data.discount_type?.name : $t('select')
              } ${$t('discound')}`"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.discount_type?.id == 'percentage' ? 'max_value:100' : ''
              }`"
            >
              <base-input
                :name="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type ? data.discount_type.name : $t('select')
                } ${$t('discound')}`"
                v-model.trim="data.amount"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: DiscountAmount -->

            <!-- Start:: UsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.usage_times')"
              class="col-lg-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.usage_times')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.usage_times')"
                v-model.number="data.usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UsageTimes -->

            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.user_usage_count')"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.usage_count ? `max_value:${data.usage_count}` : ''
              }`"
            >
              <base-input
                :disabled="!data.usage_count"
                :name="$t('labels.user_usage_count')"
                type="number"
                @keypress="preventMinus"
                :placeholder="
                  !data.usage_count
                    ? $t('select') +
                      ' ' +
                      $t('labels.usage_times') +
                      ' ' +
                      $t('firstly')
                    : $t('labels.user_usage_count')
                "
                v-model.number="data.user_usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->

            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type
                  ? $t('labels.max_amount', { name: data.discount_type.name })
                  : $t('labels.max_amount', { name: $t('monyAmount') })
              }`"
              class="col-lg-4"
              :rules="`required|min_value:1`"
            >
              <!-- <ValidationProvider
              v-slot="{ errors }"
              :name="`${
                data.discount_type
                  ? $t('labels.max_amount', { name: data.discount_type.name })
                  : $t('labels.max_amount', { name: $t('monyAmount') })
              }`"
              class="col-lg-4"
              :rules="`required|min_value:1|${
                data.amount ? `max_value:${data.amount}` : ''
              }`"
            > -->
              <base-input
                :name="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${
                  data.discount_type
                    ? $t('labels.max_amount', { name: data.discount_type.name })
                    : $t('labels.max_amount', { name: $t('monyAmount') })
                }`"
                v-model.number="data.max_amount"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->

            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :placeholder="$t('labels.start_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at_time')"
                type="time"
                v-model="data.start_time"
                :placeholder="$t('labels.start_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  Start At -->

            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                v-model="data.end_at"
                :allowedDate="data.start_at ? data.start_at : null"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at_time')"
              class="col-lg-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at_time')"
                type="time"
                v-model="data.end_time"
                :placeholder="$t('labels.end_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->

            <!-- Start:: Checks -->
            <div class="col-lg-12 py-0">
              <div class="divider">
                <h4>{{ $t("labels.status") }}</h4>
                <hr
                  role="separator"
                  aria-orientation="horizontal"
                  class="v-divider theme--dark"
                />
              </div>
            </div>
            <v-checkbox
              v-model="data.is_active"
              :label="$t('labels.is_active')"
            ></v-checkbox>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],
  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupons/show-all",
        },
        {
          text: this.$t(`breadcrumb.coupons.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      data: {
        code: null,
        start_at: null,
        start_time: null,
        end_at: null,
        max_amount: null,
        end_time: null,
        usage_count: null,
        user_usage_count: null,
        discount_type: null,
        user_type: null,
        amount: null,
        is_active: false,
        discountAppliedToJourney: "",
        nameAr: "",
        nameEn: "",
        descAr: "",
        descEn: "",
        avatar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        avatarEn: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        inner_media_ar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        inner_media_en: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
      },
      discountTypes: [
        {
          id: "amount",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage", { name: " " }),
        },
      ],
    };
  },
  methods: {
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `coupon/${this.id}`,
      })
        .then((res) => {
          this.loading = false;

          this.data = {
            code: res.data.data.code,
            discount_type: this.discountTypes.find((item) => {
              return item.id == res.data.data.descount_type;
            }),
            user_type: this.userTypes.find((item) => {
              return item.id == res.data.data.user_type;
            }),
            package: res.data.data.package,
            amount: res.data.data.amount,
            max_amount: res.data.data.max_amount,
            usage_count: res.data.data.usage_count,
            user_usage_count: res.data.data.user_usage_count,
            start_at: new Date(res.data.data.start_date).toLocaleDateString(
              "en-CA"
            ),
            end_at: new Date(res.data.data.end_date).toLocaleDateString(
              "en-CA"
            ),
            start_time: new Date(res.data.data.start_date).toLocaleTimeString(
              "en-US",
              { hour12: false }
            ),
            end_time: new Date(res.data.data.end_date).toLocaleTimeString(
              "en-US",
              { hour12: false }
            ),

            // Checks
            is_active: res.data.data.is_active,
            is_city_to_city_active: res.data.data.is_city_to_city_active,
            is_trip_active: res.data.data.is_trip_active,
            is_package_delivering_active:
              res.data.data.is_package_delivering_active,

            discountAppliedToJourney: res.data.data.applies_to_trip_no,

            nameAr: res.data.data.ar.title,
            nameEn: res.data.data.en.title,
            descAr: res.data.data.ar.desc,
            descEn: res.data.data.en.desc,

            avatar: {
              mainMedia: null,
              preview: res.data.data.ar.media,
              image: null,
              mainPreviewUrl: null,
            },
            avatarEn: {
              mainMedia: null,
              preview: res.data.data.en.media,
              image: null,
              mainPreviewUrl: null,
            },
            inner_media_ar: {
              mainMedia: null,
              preview: res.data.data.ar.inner_media,
              image: null,
              mainPreviewUrl: null,
            },
            inner_media_en: {
              mainMedia: null,
              preview: res.data.data.en.inner_media,
              image: null,
              mainPreviewUrl: null,
            },
          };
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },
    async uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        await this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
    submit() {
      this.$refs.formCoupon.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();
          data.append(
            "start_date",
            `${this.$moment(this.data.start_at).format("YYYY-MM-DD")} ${
              this.data.start_time
            }`
          );
          data.append(
            "end_date",
            `${this.$moment(this.data.end_at).format("YYYY-MM-DD")} ${
              this.data.end_time
            }`
          );
          data.append("usage_count", this.data.usage_count);
          data.append("user_usage_count", this.data.user_usage_count);
          data.append("user_type", "rider");
          data.append("descount_type", this.data.discount_type?.id);
          data.append("code", this.data.code);
          data.append("max_amount", this.data.max_amount);
          data.append("amount", this.data.amount);
          data.append("is_active", +this.data.is_active);
          data.append("ar[title]", this.data.nameAr);
          data.append("en[title]", this.data.nameEn);
          data.append("ar[desc]", this.data.descAr);
          data.append("en[desc]", this.data.descEn);

          if (this.data.avatar.image) {
            data.append("ar[media]", this.data.avatar.image);
          }
          if (this.data.avatarEn.image) {
            data.append("en[media]", this.data.avatarEn.image);
          }
          if (this.data.inner_media_ar.image) {
            data.append("ar[inner_media]", this.data.inner_media_ar.image);
          }
          if (this.data.inner_media_en.image) {
            data.append("en[inner_media]", this.data.inner_media_en.image);
          }

          data.append("applies_to_trip_no", this.data.discountAppliedToJourney);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `coupon/${this.id}`;
          } else {
            url = "coupon";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/coupons/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
