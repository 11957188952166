import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
// Home
import Home from "../views/Home.vue";

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start Profiles ==========

// ========== End Profiles ==========

// ============================== START:: CRUDS ==============================

// ******* Start Vehicles *******
import VehiclesHome from "../views/children/CRUDS/Vehicles/Home.vue";
// Show All
import AllVehicles from "../views/children/CRUDS/Vehicles/ShowAll.vue";
// Show Single
import VehicleShow from "../views/children/CRUDS/Vehicles/Show.vue";
// Add/Edit
import VehicleForm from "../views/children/CRUDS/Vehicles/Form.vue";
// ******* End Vehicles *******

// ******* Start Vehicles *******
import AirportTerminals from "../views/children/CRUDS/AirportTerminals/Home.vue";
// Show All
import AllAirportTerminals from "../views/children/CRUDS/AirportTerminals/ShowAll.vue";
// Show Single
import AirportTerminalShow from "../views/children/CRUDS/AirportTerminals/Show.vue";
// Add/Edit
import AirportForm from "../views/children/CRUDS/AirportTerminals/Form.vue";
// ******* End Vehicles *******

// ******* Start Vehilce Brand *******
import VehilceBrandHome from "../views/children/CRUDS/VehilceBrand/Home.vue";
// Show All
import AllVehilceBrand from "../views/children/CRUDS/VehilceBrand/ShowAll.vue";
// Show Single
import VehilceBrandShow from "../views/children/CRUDS/VehilceBrand/Show.vue";
// Add/Edit
import VehilceBrandForm from "../views/children/CRUDS/VehilceBrand/Form.vue";
// ******* End Vehilce Brand *******

// ******* Start Vehilce Model *******
import VehilceModelHome from "../views/children/CRUDS/VehilceModel/Home.vue";
// Show All
import AllVehilceModel from "../views/children/CRUDS/VehilceModel/ShowAll.vue";
// Show Single
import VehilceModelShow from "../views/children/CRUDS/VehilceModel/Show.vue";
// Add/Edit
import VehilceModelForm from "../views/children/CRUDS/VehilceModel/Form.vue";
// ******* End Vehilce Model *******

// ******* Start Vehilce Package *******
import VehilcePackageHome from "../views/children/CRUDS/VehilcePackage/Home.vue";
// Show All
import AllVehilcePackage from "../views/children/CRUDS/VehilcePackage/ShowAll.vue";
// Show Single
import VehilcePackageShow from "../views/children/CRUDS/VehilcePackage/Show.vue";
// Add/Edit
import VehilcePackageForm from "../views/children/CRUDS/VehilcePackage/Form.vue";
// ******* End Vehilce Package *******

// ******* Start Coupons *******
import CouponsHome from "../views/children/CRUDS/Coupons/Home.vue";
// Show All
import AllCoupons from "../views/children/CRUDS/Coupons/ShowAll.vue";
// Show Single
import CouponShow from "../views/children/CRUDS/Coupons/Show.vue";
// Add/Edit
import CouponForm from "../views/children/CRUDS/Coupons/Form.vue";
// ******* End Coupons *******

// ******* Start Cancel Reasons *******
import CancelReasonsHome from "../views/children/CRUDS/CancelReasons/Home.vue";
// Show All
import AllCancelReasons from "../views/children/CRUDS/CancelReasons/ShowAll.vue";
// Show Single
import CancelReasonShow from "../views/children/CRUDS/CancelReasons/Show.vue";
// Add/Edit
import CancelReasonForm from "../views/children/CRUDS/CancelReasons/Form.vue";
// ******* End Cancel Reasons *******

// ******* Start Report Reasons *******
import ReportReasonsHome from "../views/children/CRUDS/ReportReasons/Home.vue";
// Show All
import AllReportReasons from "../views/children/CRUDS/ReportReasons/ShowAll.vue";
// Show Single
import ReportReasonShow from "../views/children/CRUDS/ReportReasons/Show.vue";
// Add/Edit
import ReportReasonForm from "../views/children/CRUDS/ReportReasons/Form.vue";
// ******* End Report Reasons *******

// ============================== START:: CRUDS ==============================

// ========== Start:: Static Screens ==========
import StaticScreens from "../views/children/StaticScreens/Home.vue";
// ========== End:: Static Screens ==========

// ========== Start:: Settings ==========
// *********** ABOUT
import AboutUsHome from "../views/children/Settings/AboutUs/Home.vue";
// Show All
import AllAboutUs from "../views/children/Settings/AboutUs/ShowAll.vue";
// Add/Edit
import AboutUsForm from "../views/children/Settings/AboutUs/Form.vue";

// *********** Terms
import TermsHome from "../views/children/Settings/Terms/Home.vue";
// Show All
import AllTerms from "../views/children/Settings/Terms/ShowAll.vue";
// Add/Edit
import TermsForm from "../views/children/Settings/Terms/Form.vue";

// *********** Policy
import PolicyHome from "../views/children/Settings/Policy/Home.vue";
// Show All
import AllPolicy from "../views/children/Settings/Policy/ShowAll.vue";
// Add/Edit
import PolicyForm from "../views/children/Settings/Policy/Form.vue";

// ========== End:: Settings ==========

// ========== Start:: Reference ==========
import Reference from "../Reference/Reference.vue";
// ========== End:: Reference ==========

// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";
// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },

        // ============================== Start CRUDS ==============================
        // ******* Start Countries *******
        {
          path: "/countries",
          component: () => import("../views/children/CRUDS/Countries/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCountries",
              component: () =>
                import("../views/children/CRUDS/Countries/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CountryShow",
              component: () =>
                import("../views/children/CRUDS/Countries/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CountryEdit",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CountryAdd",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
            },
          ],
        },
        // ******* End Countries *******

        // ******* Start Airport terminals *******
        {
          path: "/airport-terminals",
          component: AirportTerminals,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAirportTerminals",
              component: AllAirportTerminals,
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehicleShow",
              component: AirportTerminalShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehicleEdit",
              component: AirportForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehicleAdd",
              component: AirportForm,
            },
          ],
        },

        // ******* Start Admins *******
        {
          path: "/agents",
          component: () => import("@/views/children/CRUDS/Agents/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "Allagents",
              component: () =>
                import("@/views/children/CRUDS/Agents/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "agentshow",
              component: () => import("@/views/children/CRUDS/Agents/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "agentEdit",
              component: () => import("@/views/children/CRUDS/Agents/Form.vue"),
              props: true,
            },
            {
              path: "agent-statistics",
              name: "agent-statistics",
              component: () =>
                import("@/views/children/CRUDS/Agents/Statistics.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "agentAdd",
              component: () => import("@/views/children/CRUDS/Agents/Form.vue"),
            },
          ],
        },
        // ******* End Admins *******
        // ******* Start tickets_reasons *******
        {
          path: "/tickets_reasons",
          component: () =>
            import("@/views/children/CRUDS/TiketsReason/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTiketsReason",
              component: () =>
                import("@/views/children/CRUDS/TiketsReason/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "tiketsReasonEdit",
              component: () =>
                import("@/views/children/CRUDS/TiketsReason/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "tiketsReasonAdd",
              component: () =>
                import("@/views/children/CRUDS/TiketsReason/Form.vue"),
            },
          ],
        },
        // ******* End tickets_reasons *******
        // ******* Start agents *******
        {
          path: "/admins",
          component: () => import("@/views/children/CRUDS/Admins/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAdmins",
              component: () =>
                import("@/views/children/CRUDS/Admins/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "AdminShow",
              component: () => import("@/views/children/CRUDS/Admins/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "AdminEdit",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "AdminAdd",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
            },
          ],
        },
        // ******* End agents *******
        // ******* Start Trip Requests *******
        {
          path: "/trip_requests",
          component: () =>
            import("@/views/children/CRUDS/TripRequests/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTripRequests",
              component: () =>
                import("@/views/children/CRUDS/TripRequests/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "TripRequestShow",
              component: () =>
                import("@/views/children/CRUDS/TripRequests/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "TripRequestEdit",
              component: () =>
                import("@/views/children/CRUDS/TripRequests/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "TripRequestAdd",
              component: () =>
                import("@/views/children/CRUDS/TripRequests/Form.vue"),
            },
          ],
        },
        // ******* End agents *******
        // ******* Start notifications *******
        {
          path: "/notifications",
          component: () =>
            import("@/views/children/CRUDS/notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-notifications",
              component: () =>
                import("@/views/children/CRUDS/notifications/ShowAll.vue"),
            },
            // Show Single

            {
              path: "add",
              name: "add-notification",
              component: () =>
                import("@/views/children/CRUDS/notifications/Form.vue"),
            },
          ],
        },
        // ******* End notifications *******

        // ******* Start Vehicles *******
        {
          path: "/vehicles",
          component: VehiclesHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllVehicles",
              component: AllVehicles,
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehicleShow",
              component: VehicleShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehicleEdit",
              component: VehicleForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehicleAdd",
              component: VehicleForm,
            },
          ],
        },

        // ******* End Vehicles *******
        // ******* Start lockedUsers *******
        {
          path: "/locked-users",
          component: () =>
            import("@/views/children/CRUDS/lockedUsers/ShowAll.vue"),
          name: "locked-users",
        },
        // ******* End lockedUsers *******

        // ******* Start Vehicle Brand *******
        {
          path: "/vehicle_brands",
          component: VehilceBrandHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllVehilceBrand",
              component: AllVehilceBrand,
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehilceBrandShow",
              component: VehilceBrandShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehilceBrandEdit",
              component: VehilceBrandForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehilceBrandAdd",
              component: VehilceBrandForm,
            },
          ],
        },
        // ******* End Vehicle Brand *******

        // ******* Start Vehicle Model *******
        {
          path: "/vehicle_models",
          component: VehilceModelHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllVehilceModel",
              component: AllVehilceModel,
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehilceModelShow",
              component: VehilceModelShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehilceModelEdit",
              component: VehilceModelForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehilceModelAdd",
              component: VehilceModelForm,
            },
          ],
        },
        // ******* End Vehicle Model *******
        // ******* Start Vehicle Model *******
        {
          path: "/vehicle_requirements",
          component: () =>
            import("../views/children/CRUDS/VehilceRequirement/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllVehicleRequirements",
              component: () =>
                import(
                  "../views/children/CRUDS/VehilceRequirement/ShowAll.vue"
                ),
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehicleRequirementShow",
              component: () =>
                import("../views/children/CRUDS/VehilceRequirement/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehicleRequirementEdit",
              component: () =>
                import("../views/children/CRUDS/VehilceRequirement/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehicleRequirementAdd",
              component: () =>
                import("../views/children/CRUDS/VehilceRequirement/Form.vue"),
            },
          ],
        },
        // ******* End Vehicle Model *******
        // ******* Start  Permission *******
        {
          path: "/permissions",
          component: () =>
            import("@/views/children/CRUDS/Permissions/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPermissions",
              component: () =>
                import("@/views/children/CRUDS/Permissions/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "EditPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "AddPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
            },
          ],
        },
        // ******* End  Permission *******
        // ******* Start  permissions-labels *******
        {
          path: "/permission-labels",
          component: () =>
            import("@/views/children/CRUDS/PermissionLabels/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-labels",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
            },
          ],
        },
        // ******* End  roles *******
        // ******* Start  roles *******
        {
          path: "/roles",
          component: () => import("@/views/children/CRUDS/Roles/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-roles",
              component: () =>
                import("@/views/children/CRUDS/Roles/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******
        // ******* Start  permissions-categories *******
        {
          path: "/permission-categories",
          component: () =>
            import("@/views/children/CRUDS/PermissionCategory/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******

        // ******* Start Vehilce Package *******
        {
          path: "/vehicle_packages",
          component: VehilcePackageHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllVehilcePackage",
              component: AllVehilcePackage,
            },
            // Show Single
            {
              path: "show/:id",
              name: "VehilcePackageShow",
              component: VehilcePackageShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "VehilcePackageEdit",
              component: VehilcePackageForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "VehilcePackageAdd",
              component: VehilcePackageForm,
            },
          ],
        },
        // ******* End Vehilce Package *******

        // ******* Start Coupons *******
        {
          path: "/coupons",
          component: CouponsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCoupons",
              component: AllCoupons,
            },
            // Show Single
            {
              path: "show/:id",
              name: "CouponShow",
              component: CouponShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CouponEdit",
              component: CouponForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CouponAdd",
              component: CouponForm,
            },
          ],
        },
        // ******* End Coupons *******

        // ******* Start riders *******
        {
          path: "/riders",
          component: () => import("../views/children/CRUDS/Riders/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllRiders",
              component: () =>
                import("../views/children/CRUDS/Riders/ShowAll.vue"),
            },
            {
              path: "show-all-survey",
              name: "show-all-survey",
              component: () =>
                import("../views/children/CRUDS/Riders/ShowAllSurvey.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "RiderShow",
              component: () =>
                import("../views/children/CRUDS/Riders/Show.vue"),
              props: true,
            },
            // Show Survay
            {
              path: "survay/:id",
              name: "SurvayShow",
              component: () =>
                import("../views/children/CRUDS/Riders/Survay.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "RiderEdit",
              component: () =>
                import("../views/children/CRUDS/Riders/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "RiderAdd",
              component: () =>
                import("../views/children/CRUDS/Riders/Form.vue"),
            },
          ],
        },
        // ******* End riders *******

        // ******* Start partners *******
        {
          path: "/partners",
          component: () => import("../views/children/CRUDS/Partners/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPartners",
              component: () =>
                import("../views/children/CRUDS/Partners/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "PartnerShow",
              component: () =>
                import("../views/children/CRUDS/Partners/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "PartnerEdit",
              component: () =>
                import("../views/children/CRUDS/Partners/Form.vue"),
              props: true,
            },

            {
              path: "partners_tracking",
              name: "partners_tracking",
              component: () =>
                import("../views/children/CRUDS/Partners/DriversTracking.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "PartnerAdd",
              component: () =>
                import("../views/children/CRUDS/Partners/Form.vue"),
            },
          ],
        },
        // ******* End partners *******
        // ******* Start Companies *******
        {
          path: "/companies",
          component: () => import("../views/children/CRUDS/Companies/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCompanies",
              component: () =>
                import("../views/children/CRUDS/Companies/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "CompanyEdit",
              component: () =>
                import("../views/children/CRUDS/Companies/Form.vue"),
              props: true,
            },
            // Show
            {
              path: "show/:id",
              name: "CompanyShow",
              component: () =>
                import("../views/children/CRUDS/Companies/Show.vue"),
              props: true,
            },

            {
              path: "add",
              name: "CompanyAdd",
              component: () =>
                import("../views/children/CRUDS/Companies/Form.vue"),
            },
          ],
        },
        // ******* End Companies *******

        // ******* Start Cancel Reason *******
        {
          path: "/cancel_reasons",
          component: CancelReasonsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCancelReasons",
              component: AllCancelReasons,
            },
            // Show Single
            {
              path: "show/:id",
              name: "CancelReasonShow",
              component: CancelReasonShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CancelReasonEdit",
              component: CancelReasonForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CancelReasonAdd",
              component: CancelReasonForm,
            },
          ],
        },
        // ******* End Cancel Reason *******

        // ******* Start Report Reasons *******
        {
          path: "/report_reasons",
          component: ReportReasonsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllReportReasons",
              component: AllReportReasons,
            },
            // Show Single
            {
              path: "show/:id",
              name: "ReportReasonShow",
              component: ReportReasonShow,
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "ReportReasonEdit",
              component: ReportReasonForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "ReportReasonAdd",
              component: ReportReasonForm,
            },
          ],
        },
        // ******* End Report Reasons *******

        // ******* Start Orders *******
        {
          path: "/trips",
          component: () => import("../views/children/CRUDS/Trips/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTrips",
              component: () =>
                import("../views/children/CRUDS/Trips/ShowAll.vue"),
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/children/CRUDS/Trips/Show/ShowHome.vue"),

              props: true,
              children: [
                {
                  path: "",
                  name: "OrderDetails",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderDetails.vue"
                    ),

                  props: true,
                },
                {
                  path: "invoice",
                  name: "invoice",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Invoice.vue"),

                  props: true,
                },
                {
                  path: "candidates",
                  name: "candidates",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Candidates.vue"),

                  props: true,
                },
                {
                  path: "orderChat",
                  name: "OrderChat",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderChat.vue"),

                  props: true,
                },
                {
                  path: "offersChat/:offer_id",
                  name: "OffersChat",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OffersChat.vue"),

                  props: true,
                },
                {
                  path: "partner",
                  name: "partner",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Partner.vue"),

                  props: true,
                },
                {
                  path: "reportReason",
                  name: "ReportReason",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/ReportReason.vue"
                    ),

                  props: true,
                },
                {
                  path: "complaint",
                  name: "Complaint",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Complaint.vue"),

                  props: true,
                },
                {
                  path: "orderOffers",
                  name: "OrderOffers",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderOffers.vue"),

                  props: true,
                },
                {
                  path: "orderRates",
                  name: "OrderRates",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderRates.vue"),

                  props: true,
                },
                {
                  path: "orderPayment",
                  name: "OrderPayment",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderPayment.vue"
                    ),

                  props: true,
                },
                {
                  path: "orderPackage",
                  name: "OrderPackage",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderPackage.vue"
                    ),

                  props: true,
                },
              ],
            },
            {
              path: "user_orders/:id/:type",
              name: "AllUserOrders",
              component: () =>
                import("@/views/children/CRUDS/Trips/AllUserOrders.vue"),
              props: true,
            },
            {
              path: "complaint-trips",
              name: "AllComplaintTrips",
              component: () =>
                import("@/views/children/CRUDS/Trips/Complaint.vue"),
            },
            {
              path: "unusual_behaviour",
              name: "unusual_behaviour",
              component: () =>
                import("@/views/children/CRUDS/Trips/UnusualBehaviour.vue"),
            },
          ],
        },
        // ******* End Orders *******

        // // ******* Start withdraw *******
        {
          path: "/withdraw",
          component: () => import("@/views/children/CRUDS/withdraw/Home.vue"),

          children: [
            // Show All
            {
              path: "show-all",
              name: "AllWithdraws",
              component: () =>
                import("@/views/children/CRUDS/withdraw/ShowAll.vue"),
            },
          ],
        },
        // ******* End withdraw *******
        // // ******* Start Update Requests *******
        {
          path: "/update-requests",
          component: () =>
            import("@/views/children/CRUDS/UpdateRequests/Home.vue"),

          children: [
            // Show All
            {
              path: "show-all",
              name: "AllRequests",
              component: () =>
                import("@/views/children/CRUDS/UpdateRequests/ShowAll.vue"),
            },
          ],
        },
        // ******* End Update Requests *******

        // ******* Start Live  Chat*******
        {
          path: "/chats/:id?",
          component: () => import("@/views/children/Chat/Index.vue"),
          name: "chats",
          props: true,
        },
        // ******* End  Live  Chat *******

        // ******* Start Live  Chat*******
        {
          path: "/tickets",
          component: () => import("@/views/children/CRUDS/Tickets/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "Tickets",
              component: () =>
                import("@/views/children/CRUDS/Tickets/ShowAll.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "AdminShow",
            //   component: () => import("@/views/children/CRUDS/Tickets/Show.vue"),
            //   props: true,
            // },
            // // Edit
            {
              path: "reply/:id",
              name: "Reply",
              component: () =>
                import("@/views/children/CRUDS/Tickets/Form.vue"),
              props: true,
            },
            // // Add
            // {
            //   path: "add",
            //   name: "AdminAdd",
            //   component: () => import("@/views/children/CRUDS/Tickets/Form.vue"),
            // },
          ],
        },
        // {
        //   path: "/tickets/show-all",
        //   component: () => import("@/views/children/Tickets/ShowAll.vue"),
        //   name: "tickets",
        //   props: true,
        // },
        // ******* End  Live  Chat *******

        // ============================== End CRUDS ==============================

        // ========== Start StaticScreens ==========
        {
          path: "/static-screens",
          name: StaticScreens,
          component: StaticScreens,
        },
        // ========== End StaticScreens ==========

        // ========== Start Settings ==========
        // ***** Anout Us
        {
          path: "/about",
          component: AboutUsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAboutUs",
              component: AllAboutUs,
            },
            // Edit
            {
              path: "edit/:id",
              name: "AboutUsEdit",
              component: AboutUsForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "AboutUsAdd",
              props: true,
              component: AboutUsForm,
            },
          ],
        },
        // ***** Faq
        {
          path: "/faqs",
          component: () => import("@/views/children/Settings/Faq/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllFaqs",
              component: () =>
                import("@/views/children/Settings/Faq/ShowAll.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "editAdd",
              component: () => import("@/views/children/Settings/Faq/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "faqAdd",
              component: () => import("@/views/children/Settings/Faq/Form.vue"),
            },
          ],
        },

        // ***** Terms
        {
          path: "/terms",
          component: TermsHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTerms",
              component: AllTerms,
            },
            // Edit
            {
              path: "edit/:id",
              name: "TermsEdit",
              component: TermsForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "TermsAdd",
              component: TermsForm,
            },
          ],
        },
        {
          path: "/use-term",
          component: () =>
            import("@/views/children/Settings/use-term/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "useTerms",
              component: () =>
                import("@/views/children/Settings/use-term/ShowAll.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "useTermsEdit",
              component: () =>
                import("@/views/children/Settings/use-term/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "useTermsAdd",
              component: () =>
                import("@/views/children/Settings/use-term/Form.vue"),
            },
          ],
        },

        // ***** Policy
        {
          path: "/policy",
          component: PolicyHome,
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPolicy",
              component: AllPolicy,
            },
            // Edit
            {
              path: "edit/:id",
              name: "PolicyEdit",
              component: PolicyForm,
              props: true,
            },
            // Add
            {
              path: "add",
              name: "PolicyAdd",
              component: PolicyForm,
            },
          ],
        },

        // ***** Contact
        {
          path: "/settings",
          component: () => import("../views/children/Settings/Home.vue"),
          children: [
            {
              path: "",
              name: "mainSettings",
              component: () => import("../views/children/Settings/General.vue"),
            },
            {
              path: "social",
              name: "socialSettings",
              component: () => import("../views/children/Settings/Social.vue"),
            },
            {
              path: "sms",
              name: "sms",
              component: () =>
                import("../views/children/Settings/SmsServices.vue"),
            },
            {
              path: "order-setting",
              name: "order-setting",
              component: () => import("../views/children/Settings/Orders.vue"),
            },
            {
              path: "app-links",
              name: "app-links",
              component: () =>
                import("../views/children/Settings/AppLinks.vue"),
            },
          ],
        },
        // ========== End Settings ==========

        // ========== Start Profiles ==========
        {
          path: "/profile",

          component: () => import("@/views/children/adminProfile/Home.vue"),
          children: [
            {
              path: "show",
              name: "showProfile",
              component: () => import("@/views/children/adminProfile/Show.vue"),
            },
            {
              path: "edit",
              name: "editProfile",
              component: () => import("@/views/children/adminProfile/Form.vue"),
            },
            {
              path: "password",
              name: "changePassword",
              component: () =>
                import("@/views/children/adminProfile/ChangePassword.vue"),
            },
          ],
        },
        // ========== End Profiles ==========

        // ========== Start Reference==========
        {
          path: "/reference",
          name: "Reference",
          component: Reference,
        },
        // ========== End Reference ==========

        // ========== Start Chat ==========
        {
          path: "/financial_reports",
          name: "FinancialReports",
          component: () =>
            import("../views/children/FinancialReports/Home.vue"),
        },
        // ========== Start Chat ==========

        // ========== End Chat ==========

        // ========== Start DriversTracking ==========
        // {
        //   path: '/drivers_tracking',
        //   name: 'DriversTracking',
        //   component: DriversTracking,
        // },
        // ========== End DriversTracking ==========
      ],
    },
    {
      path: "/permission-required",
      name: "permission-required",
      component: () => import("../views/PermissionRequired.vue"),
    },

    //  =========== Start Redirects ===============
    {
      path: "/countries",

      redirect: () => {
        return { path: "/countries/show-all" };
      },
    },
    {
      path: "/airport-terminals",

      redirect: () => {
        return { path: "/airport-terminals/show-all" };
      },
    },
    {
      path: "/about",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    {
      path: "/about/add",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms/add",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy/add",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    //  =========== End  Redirects ===============
    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, _2, next) => {
  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
