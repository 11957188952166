<template>
  <Loader v-if="loading"></Loader>

  <div v-else>
    <Breadcrumb :items="items" />

    <noData v-if="noData" />

    <div class="form countryForm custom_card fadeIn" v-else>
      <ValidationObserver ref="formVehicles" @submit.prevent="submit">
        <div class="row">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.plate_numbers')"
            class="col-md-6 col-lg-4"
            rules="min:1|min_value:1"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.ordering"
              :placeholder="$t('labels.ordering')"
              type="number"
              :name="$t('labels.ordering')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.airportNameAr')"
            class="col-md-6 col-lg-4"
            rules="required|min:2"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.ar.airport_name"
              :placeholder="$t('labels.airportNameAr')"
              type="text"
              :name="$t('labels.airportNameAr')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.airportNameEn')"
            class="col-md-6 col-lg-4"
            rules="required|min:2"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.en.airport_name"
              :placeholder="$t('labels.airportNameEn')"
              type="text"
              :name="$t('labels.airportNameEn')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.nameAr')"
            class="col-md-6 col-lg-4"
            rules="required|min:2"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.ar.name"
              :placeholder="$t('labels.nameAr')"
              type="text"
              :name="$t('labels.nameAr')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.nameEn')"
            class="col-md-6 col-lg-4"
            rules="required|min:2"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.en.name"
              :placeholder="$t('labels.nameEn')"
              type="text"
              :name="$t('labels.nameEn')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.status") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.is_active')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("active", { name: $t("labels.vehicle") }) }}</h4>
              </div>

              <v-checkbox
                :classes="errors[0] ? 'error' : ''"
                :name="$t('labels.is_active')"
                class="py-0 px-0"
                v-model="data.is_active"
                :label="$t('labels.is_active')"
              ></v-checkbox>
            </div>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.locationHall") }}</h4>

              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
        </div>

        <div class="col-12">
          <select-map
            :biggingMarkers="center"
            @makerChanged="hallMarkerChanged"
            id="1"
          />
        </div>

        <div class="col-lg-12 py-0">
          <div class="divider">
            <h4>{{ $t("labels.locationParking") }}</h4>

            <hr
              role="separator"
              aria-orientation="horizontal"
              class="v-divider theme--dark"
            />
          </div>
        </div>

        <div class="col-12">
          <select-map
            :biggingMarkers="center2"
            @makerChanged="parkingMarkerChanged"
            id="2"
          />
        </div>

        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="submit"
            :disabled="btnLoading"
            :loading="btnLoading"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import BaseCountryFlagPhoneInput from "@/components/Forms/BaseCountryFlagPhoneInput.vue";

export default {
  components: { BaseCountryFlagPhoneInput },
  props: ["id"],
  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.airport.title"),
          disabled: false,
          href: "/airport-terminals/show-all",
        },
        {
          text: this.$t(`breadcrumb.airport.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      btnLoading: false,
      data: {
        ordering: 1,
        ar: {
          airport_name: "",
          name: "",
        },
        en: {
          airport_name: "",
          name: "",
        },
        is_active: false,
        hall_lat: "",
        hall_lng: "",
        parking_lat: "",
        parking_lng: "",
      },
      noData: false,
      center: null,
      center2: null,
    };
  },
  methods: {
    async getEditData() {
      this.noData = false;
      this.loading = true;
      await this.axios({
        method: "GET",
        url: `terminal/${this.id}`,
      })
        .then((res) => {
          this.data = {
            ...res.data.data,
            is_active: res.data.data.is_active,
            location: res.data.data.location,
            hall_lat: +res.data.data.hall_lat,
            hall_lng: +res.data.data.hall_lng,
            parking_lat: +res.data.data.parking_lat,
            parking_lng: +res.data.data.parking_lng,
          };
          this.center = {
            lat: +res.data.data.hall_lat,
            lng: +res.data.data.hall_lng,
          };
          this.center2 = {
            lat: +res.data.data.parking_lat,
            lng: +res.data.data.parking_lng,
          };

          console.log(this.data);
        })
        .catch((err) => {
          console.error(err);
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });

          this.noData = true;
        })
        .finally(() => (this.loading = false));
    },
    hallMarkerChanged(e) {
      this.data.hall_lat = e.lat;
      this.data.hall_lng = e.lng;
    },
    parkingMarkerChanged(e) {
      this.data.parking_lat = e.lat;
      this.data.parking_lng = e.lng;
    },
    async submit() {
      this.btnLoading = true;

      let message = this.id ? this.$t("editSuccess") : this.$t("addSuccess");
      await this.axios({
        method: "POST",
        url: this.id ? `/terminal/${this.id}` : "/terminal",
        data: this.id
          ? { ...this.data, is_active: +this.data.is_active, _method: "put" }
          : { ...this.data, is_active: +this.data.is_active },
      })
        .then(() => {
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message: message,
          });
          this.btnLoading = false;
          this.$router.push("/airport-terminals/show-all");
        })
        .catch((e) => console.log(e))
        .finally(() => (this.btnLoading = false));
    },
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },
  async created() {
    this.control = this.$permission.setControls(this.$route.path);

    if (this.editMode) {
      await this.getEditData();
    }
  },
};
</script>

<style lang="scss">
.v-input--radio-group--column {
  .v-input--radio-group__input {
    flex-direction: row !important;
  }
}
.v-input--selection-controls.v-input {
  margin-bottom: 0 !important;
  .v-messages {
    display: none !important;
  }
}
// .v-input--radio-group.v-input .v-input__control .v-input__slot {
//   padding-top: 0 !important;
// }
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
.vehicle_requirement {
  margin-top: 10px;

  padding: 6px;

  .input-title {
    min-width: 200px;
    padding-top: 15px !important;
    h4 {
      margin: 0 !important;
    }
  }
}
</style>
