<template>
  <div class="homePage">
    <Loader v-if="loading" />
    <div v-else>
      <NoData v-if="noData" />
      <div class="" ref="main_wrapper" v-else>
        <div class="row fadeIn">
          <!-- End:: Aside -->
          <div class="col-md-7 col-xl-9 py-0">
            <main>
              <!-- Start:: Small Screens Charts Button -->
              <button class="charts_toggeler" @click="toggleAside">
                <i class="fas fa-chart-pie"></i>
              </button>
              <!-- End:: Small Screens Charts Button -->

              <!-- ============== Cards -->
              <div class="row">
                <div
                  class="col-lg-6 col-xxl-4"
                  v-for="top in top_cards"
                  :key="top.id"
                >
                  <top-chart-state
                    :item="top"
                    :color="getRandomColor(colors)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xxl-4" v-for="card in cards" :key="card.id">
                  <simple-card classes="w-100 ">
                    <statistics-card
                      :item="card"
                      :color="getRandomColor(colors)"
                    />
                  </simple-card>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <simple-card classes="w-100 m-0">
                    <main>
                      <v-data-table
                        class="thumb strip"
                        :headers="top_rider_headers"
                        :items="top_rider"
                        :loading="loading"
                        :loading-text="$t('table.loadingData')"
                        item-key="id"
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:[`item.index`]="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.avatar`]="{ item, index }">
                          <img
                            @click="show_model_1(item.avatar)"
                            class="image"
                            @error="imageError(index, 'top_rider')"
                            :src="item.avatar"
                            v-if="item.avatar"
                          />
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>

                        <template v-slot:[`item.name`]="{ item }">
                          <router-link
                            v-if="item.full_name"
                            :to="`/riders/show/${item?.id}`"
                          >
                            <span>
                              {{ item.full_name }}
                            </span>
                          </router-link>
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                          <span v-if="item.email">
                            {{ item.email }}
                          </span>

                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>

                        <!-- Select no data State -->
                        <template v-slot:no-data>
                          {{ $t("table.noData") }}
                        </template>

                        <!-- IsActive -->
                        <template v-slot:[`item.is_active`]="{ item }">
                          <span
                            class="status"
                            :class="item.is_active ? 'active' : 'inactive'"
                          >
                            {{ helper_boolToText(item.is_active) }}
                          </span>
                        </template>

                        <template
                          v-slot:[`item.phone_complete_form`]="{ item }"
                        >
                          <a
                            :href="`tel:${item.phone_complete_form}`"
                            v-if="item.phone_complete_form"
                            ><bdi> {{ item.phone_complete_form }}</bdi></a
                          >
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <div class="_actions">
                            <router-link :to="`/riders/show/${item.id}`">
                              <v-icon class="show" small>
                                fal fa-eye
                              </v-icon>
                            </router-link>
                          </div>
                        </template>

                        <!-- ======================== Start Top Section ======================== -->
                        <template v-slot:top>
                          <h3 class="table-title title">
                            {{ $t("labels.topRiders") }}
                          </h3>
                          <!-- Delete dialog -->

                          <!-- Add & Delete -->
                          <v-row>
                            <v-col cols="12" sm="8"></v-col>
                          </v-row>
                        </template>
                        <!-- ======================== End Top Section ======================== -->
                      </v-data-table>
                    </main>
                  </simple-card>
                </div>

                <div class="col-12">
                  <simple-card classes="w-100 m-0">
                    <main>
                      <v-data-table
                        class="thumb strip"
                        :headers="top_partners_headers"
                        :items="top_partners"
                        :loading="loading"
                        :loading-text="$t('table.loadingData')"
                        item-key="id"
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:[`item.index`]="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.avatar`]="{ item, index }">
                          <img
                            @click="show_model_1(item.avatar)"
                            class="image"
                            @error="imageError(index, 'top_partners')"
                            :src="item.avatar"
                            v-if="item.avatar"
                          />
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>

                        <template v-slot:[`item.full_name`]="{ item }">
                          <router-link
                            v-if="item.full_name"
                            :to="`/partners/show/${item?.id}`"
                          >
                            <span>
                              {{ item.full_name }}
                            </span>
                          </router-link>
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                          <span v-if="item.email">
                            {{ item.email }}
                          </span>

                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>
                        <template
                          v-slot:[`item.phone_complete_form`]="{ item }"
                        >
                          <a
                            :href="`tel:${item.phone_complete_form}`"
                            v-if="item.phone_complete_form"
                            ><bdi> {{ item.phone_complete_form }}</bdi></a
                          >
                          <span class="redColor fontBold" v-else>{{
                            $t("notFound")
                          }}</span>
                        </template>

                        <!-- Select no data State -->
                        <template v-slot:no-data>
                          {{ $t("table.noData") }}
                        </template>

                        <!-- IsActive -->
                        <template v-slot:[`item.is_active`]="{ item }">
                          <span
                            class="status"
                            :class="item.is_active ? 'active' : 'inactive'"
                          >
                            {{ helper_boolToText(item.is_active) }}
                          </span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <div class="_actions">
                            <router-link :to="`/partners/show/${item.id}`">
                              <v-icon class="show" small>
                                fal fa-eye
                              </v-icon>
                            </router-link>
                          </div>
                        </template>

                        <!-- ======================== Start Top Section ======================== -->
                        <template v-slot:top>
                          <h3 class="table-title title">
                            {{ $t("labels.topPartners") }}
                          </h3>
                          <!-- Delete dialog -->

                          <!-- Add & Delete -->
                          <v-row>
                            <v-col cols="12" sm="8"></v-col>
                          </v-row>
                        </template>
                        <!-- ======================== End Top Section ======================== -->
                      </v-data-table>
                    </main>
                  </simple-card>
                </div>
              </div>
            </main>
          </div>
          <!-- Start:: Aside -->
          <div class="col-md-5 col-xl-3 py-0">
            <simple-card classes="w-100">
              <div class="notifications_states">
                <header>
                  <h2 class="title">
                    {{ $t("labels.notifications") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="notifications_states.length">
                      <router-link
                        class="state"
                        v-for="notfy in notifications_states"
                        :key="notfy.id"
                        :to="`/${getRouteLink(notfy)}/show/${notfy.data_id}`"
                      >
                        <div class="notification_content">
                          <h5 class="title">
                            {{ notfy.title[$i18n.locale] }}
                          </h5>
                          <p :title="notfy.body[$i18n.locale]">
                            {{ notfy.body[$i18n.locale].slice(0, 50)
                            }}{{
                              notfy.body[$i18n.locale].length > 50 ? "..." : ""
                            }}
                          </p>
                        </div>
                      </router-link>
                    </div>
                    <div v-else class="text-center no-data">
                      <img
                        src="@/assets/media/images/Others/no_notifications.svg"
                        alt="No Notifications"
                        width="100"
                      />
                      <h4>{{ $t("navbar.notification.no_notifications") }}</h4>
                    </div>
                  </div>
                </div>
                <footer v-if="notifications_states.length">
                  <router-link to="/notifications/show-all">{{
                    $t("BUTTONS.seeMore")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topRiders") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="recently_rider.length">
                      <div
                        class="state"
                        v-for="item in recently_rider"
                        :key="item.id"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.avatar"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <router-link
                            :to="`/riders/show/${item.id}`"
                            class="title"
                            >{{ item.full_name }}</router-link
                          >
                          <a :href="`tel:${item.phone_complete_form}`">
                            <bdi> {{ item.phone_complete_form }}</bdi>
                          </a>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/riders/show-all">{{
                    $t("breadcrumb.riders.all")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topPartners") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="recently_partner.length">
                      <div
                        class="state"
                        v-for="item in recently_partner"
                        :key="item.id"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.avatar"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <router-link
                            :to="`/partners/show/${item.id}`"
                            class="title"
                            >{{ item.full_name }}</router-link
                          >
                          <a :href="`tel:${item.phone_complete_form}`">
                            <bdi> {{ item.phone_complete_form }}</bdi>
                          </a>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/partners/show-all">{{
                    $t("breadcrumb.partners.all")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topPackages") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="top_vehicle_package.length">
                      <div
                        v-for="item in top_vehicle_package"
                        :key="item.id"
                        class="align-items-start border-bottom pb-1 state"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.image"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <h5 class="title">{{ item[$i18n.locale].title }}</h5>
                          <p>
                            <bdi>
                              {{ $t("labels.base_fare") }}
                            </bdi>
                            <bdi>
                              <span>{{ item.base_fare }}</span>
                            </bdi>
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi>
                              {{ $t("labels.minimum_fare") }}
                            </bdi>
                            <bdi>
                              <span>{{ item.minimum_fare }}</span>
                            </bdi>
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi> {{ $t("labels.price_per_kilo") }} :</bdi>
                            <bdi>
                              <span>{{ item.price_per_kilo }}</span></bdi
                            >
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi> {{ $t("labels.price_per_minute") }} :</bdi>
                            <bdi
                              ><span>{{ item.price_per_minute }}</span></bdi
                            >
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/vehicle_packages/show-all"
                    >{{ $t("breadcrumb.vehicle_packages.all") }}
                  </router-link>
                </footer>
              </div>
            </simple-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartsAside from "@/components/Layouts/ChartsAside.vue";
import Loader from "@/components/Loaders/loader.vue";

import AreaCharts from "@/components/Charts/AreaCharts.vue";
import ColumnChart from "@/components/Charts/ColumnChart.vue";

export default {
  name: "HomePage",

  components: {
    ChartsAside,
    AreaCharts,
    ColumnChart,
    Loader,
  },

  data() {
    return {
      colors: [
        {
          first: "#62EAE1",
          secound: "rgba(126, 229, 223, 0)",
        },
        {
          first: "#FF6370",
          secound: "rgba(255, 99, 112, 0)",
        },
        {
          first: "#9E62EA",
          secound: " #B199ED",
        },
        {
          first: "#EABC62",
          secound: "#E9BA62",
        },
      ],

      // ========== Charts Aside
      asideIsActive: false,
      // start of main loader
      noData: true,
      loading: false,
      // end of main loader
      // ============ Charts Data
      cards: [],
      notifications_states: [],
      charts: [],
      top_cards: [],
      recently_partner: [],
      recently_rider: [],
      top_vehicle_package: [],
      top_partners: [],
      top_rider: [],
      chartsData: null,

      top_rider_headers: [
        {
          text: "الصورة",
          align: "center",
          value: "avatar",
          sortable: false,
        },
        {
          text: this.$t("labels.name"),
          value: "name",
          align: "center",
          sortable: false,
        },

        {
          text: this.$t("labels.email"),
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.phone"),
          align: "center",
          value: "phone_complete_form",
          sortable: false,
        },
        {
          text: this.$t("labels.activation"),
          align: "center",
          value: "is_active",
          sortable: false,
        },

        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      top_partners_headers: [
        {
          text: "الصورة",
          align: "center",
          value: "avatar",
          sortable: false,
        },
        {
          text: this.$t("labels.fullname"),
          value: "full_name",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.email"),
          value: "email",
          align: "center",
          sortable: false,
        },

        {
          text: this.$t("labels.phone"),
          align: "center",
          value: "phone_complete_form",
          sortable: false,
        },
        {
          text: this.$t("labels.is_active"),
          align: "center",
          value: "is_active",
          sortable: false,
        },

        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth_module/currentUser"];
    },
  },

  methods: {
    toggleAside() {
      this.asideIsActive = !this.asideIsActive;
    },
    getRouteLink(item) {
      let link;
      if (item.notify_type == "new_trip") {
        link = "trips";
      } else if (item.notify_type == "new_rider") {
        link = "riders";
      } else if (item.notify_type == "new_partner") {
        link = "partners";
      } else if (item.notify_type == "finish_trip") {
        link = "trips";
      }

      return link;
    },
    getHome() {
      this.canShow = this.$permission.allowed("home/show");
      if (!this.canShow) {
        this.loading = true;
        this.noData = false;
        this.axios({
          method: "GET",
          url: "/home",
        })
          .then((res) => {
            this.noData = false;
            this.cards = res.data.data.cards;
            this.notifications_states = res.data.data.notifications;
            this.top_cards = res.data.data.top_cards;
            this.recently_partner = res.data.data.recently_partner;
            this.recently_rider = res.data.data.recently_rider;
            this.top_vehicle_package = res.data.data.top_vehicle_package;
            this.top_rider = res.data.data.top_rider;
            this.top_partners = res.data.data.top_partners;
            // this.charts = res.data.data.charts;
            // this.chartsData = {
            //   charts: res.data.data.side_charts,
            // };
            this.loading = false;
          })
          .catch((err) => {
            this.noData = true;
            this.loading = false;
            this.$iziToast.error({
              displayMode: 2,
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
          });
      }
    },

    getRandomColor(colors) {
      let random = Math.floor(Math.random() * 4);

      return { colors: colors[random], id: random };
    },
    imageError(index, inp) {
      this[inp][
        index
      ].avatar = require("@/assets/media/images/Icons/no-data.gif");
    },
    getPermissions() {
      this.axios.get("get_my_permissions").then((res) => {
        localStorage.setItem("bro_permissions", JSON.stringify(res.data.data));
        this.getHome();
        this.getSideBar();
      });
    },
    getControls() {
      this.axios
        .get("get_my_control_permissions")
        .then((res) =>
          localStorage.setItem("bro_controls", JSON.stringify(res.data.data))
        );
    },
    getSideBar() {
      this.axios.get("admin_side_bar").then((res) => {
        this.$router.push(`/${res.data[0].categories[0].permissions[0].url}`);
      });
    },
  },

  mounted() {},
  created() {
    if (this.user.usertype != "superadmin") {
      this.getPermissions();
      this.getControls();
    } else {
      if (localStorage.getItem("bro_controls")) {
        localStorage.removeItem("bro_controls");
      }
      if (localStorage.getItem("bro_permissions")) {
        localStorage.removeItem("bro_permissions");
      }
      this.getHome();
    }
  },
};
</script>

<style lang="scss">
.stats_chart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  // justify-content: space-c;
  padding: 15px;
  // flex-direction: row-reverse;
  .icon {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 1rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #000;
    }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
      color: var(--mainText);
    }
    .values {
      margin-top: 0.5rem;
      span {
        color: var(--main);
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }
}
.notifications_states {
  header {
    border-bottom: 1px solid #efefef !important;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 20px;
      color: #000;
    }
  }
  .content {
    min-height: 200px;
    max-height: 250px;
    overflow-y: auto;
    padding-block: 8px;
    .notification_card {
      .state {
        display: flex;
        align-items: center;
        .notification_content {
          margin-inline-start: 8px;
          .title {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 17px;
            color: #000;
            margin: 0;
            margin-bottom: 5px;
          }
          p,
          a {
            font-style: normal;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 15px;
            color: var(--mainText);
            margin: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 18px !important;
        }
      }
    }
  }
  footer {
    text-align: center;
    border-top: 1px solid #efefef !important;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--mainText);
    }
  }

  &.client {
    .content {
      min-height: 250px;
      max-height: 250px;
    }
  }
}
</style>
