<template>
  <div class="countries_all">
    <template>
      <!-- <the-filter :inputs="inputs" /> -->
      <!-- @filterFired="filterFired" -->
      <Breadcrumb
        :canAddNew="!canAdd"
        :items="items"
        search_route="/vehicles/add"
        :search_title="$t('breadcrumb.vehicles.add')"
        icon="fa-plus"
      />
      <the-filter :inputs="inputs" />
      <!-- :custom-filter="helper_filterSearch" -->

      <main>
        <div class="col-12 mb-2 pt-0">
          <div class="row">
            <simple-card v-for="(card, index) in statistics" :key="index">
              <statistics-card :item="card" :color="getRandomColor()" />
            </simple-card>
          </div>
        </div>
        <v-data-table
          class="thumb strip"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :expanded.sync="expanded"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Expanded -->
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" class="expandDate fadeIn">
              <span class="expandTitle">المزيد من التفاصيل</span>
              <ul>
                <li v-for="(value, key) of expandData" :key="key">
                  <span class="key">{{ key }}</span>
                  <span class="value">
                    <template v-if="value != null && value != 'null'">
                      {{ value }}
                    </template>
                    <template v-else-if="value == null || value == 'null'">
                      {{ $t("table.noData") }}
                    </template>
                  </span>
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:[`item.extendIcon`]="{ item }">
            <div class="_actions">
              <v-icon class="show" small @click="clickRow(item)">
                fas fa-angle-double-down
              </v-icon>
            </div>
          </template>
          <!-- IsActive -->
          <template v-slot:[`item.is_active`]="{ item }">
            <v-switch
              :disabled="button_.id == item.id || canEdit"
              :loading="button_.id == item.id"
              color="#26BE00"
              v-model="item.is_active"
              @change="changeActiveStatus(item)"
            ></v-switch>
            {{ item.is_active ? $t("active") : $t("inactive") }}
          </template>

          <template v-slot:[`item.is_in_order`]="{ item }">
            <v-switch
              :disabled="button_.id == item.id || canEdit"
              :loading="button_.id == item.id"
              color="#26BE00"
              v-model="item.is_in_order"
              @change="changeTripStatus(item)"
            ></v-switch>
            {{
              item.is_in_order
                ? $t("labels.in_order")
                : $t("labels.not_in_order")
            }}
          </template>
          <template v-slot:[`item.is_locked`]="{ item }">
            <v-switch
              :disabled="button_.id == item.id || canEdit"
              :loading="button_.id == item.id"
              color="#26BE00"
              v-model="item.is_locked"
              @change="changeLockStatus(item)"
            ></v-switch>
            {{ item.is_locked ? $t("locked") : $t("unlocked") }}
          </template>
          <template v-slot:[`item.vehicle_package_id`]="{ item }">
            <span v-if="item.vehicle_package_id">{{
              item.vehicle_package_id.title
            }}</span>
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.partner_id`]="{ item }">
            <router-link
              :to="`/partners/show/${item.partner_id.id}`"
              v-if="item.partner_id"
              >{{ item.partner_id.full_name }}</router-link
            >
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.vehicle_model_id.name`]="{ item }">
            <span v-if="item.vehicle_model_id.name">{{
              item.vehicle_model_id.name
            }}</span>
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.color`]="{ item }">
            <span v-if="item.color">{{ item.color }}</span>
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.image`]="{ item, index }">
            <img
              class="image"
              :src="item.image"
              @error="imageError(index)"
              @click="show_model_1(item.image)"
              v-if="item.image"
            />
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:expand>
            <ul>
              <li v-for="(value, key) of expandData" :key="key">
                <span class="key">{{ key }}</span>
                <span class="value">
                  <template v-if="value != null && value != 'null'">
                    {{ value }}
                  </template>
                  <template v-else-if="value == null || value == 'null'">
                    {{ $t("table.noData") }}
                  </template>
                </span>
              </li>
            </ul>
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="show"
                v-if="!canShow"
                small
                @click="showItem(item)"
              >
                fal fa-eye
              </v-icon>
              <v-icon
                class="edit"
                v-if="!canEdit"
                small
                @click="editItem(item)"
              >
                fal fa-edit
              </v-icon>
              <deleter
                v-if="!canDelete"
                :items="rows"
                name="vehicle"
                :id="item.id"
                @refresh="rows = $event"
              />
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <h3 class="table-title title">
              {{ $t("breadcrumb.vehicles.title") }}
              <span class="total">({{ total }})</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    far fa-info-circle
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
            </h3>
            <!-- Delete dialog -->

            <!-- Add & Delete -->

            <v-row>
              <v-col cols="12" sm="8"></v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <v-pagination
              color="primary"
              v-model="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </main>

      <!-- Start Image_Model -->
      <base-model
        @close="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image text-center">
          <img
            class="img-fluid"
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicles.title"),
          disabled: false,
          href: "/vehicles/show-all",
        },
        {
          text: this.$t("breadcrumb.vehicles.all"),
          disabled: true,
          href: "",
        },
      ],
      expanded: [],

      loading: false,
      button_: {
        id: "",
      },
      // ========== headers
      headers: [
        {
          text: this.$t("labels.image"),
          align: "center",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("labels.partner"),
          align: "center",
          value: "partner_id",
          sortable: false,
        },
        {
          text: this.$t("labels.model"),
          value: "vehicle_model_id.name",
          align: "center",
        },

        {
          text: this.$t("labels.package"),
          value: "vehicle_package_id",
          align: "center",
        },
        {
          text: this.$t("labels.color"),
          value: "color",
          align: "center",
        },
        {
          text: this.$t("labels.is_active"),
          value: "is_active",
          align: "center",
        },
        {
          text: this.$t("labels.is_in_order"),
          value: "is_in_order",
          align: "center",
        },
        {
          text: this.$t("labels.is_locked"),
          value: "is_locked",
          align: "center",
        },

        {
          text: this.$t("labels.extra"),
          value: "extendIcon",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      statistics: [],
      // ========== Filter
      inputs: [
        {
          keyName: "keyword",
        },

        {
          keyName: "model_id",
          url: "/vehicle_model_not_paginated", // For Auto Complete To Search
        },
        {
          keyName: "package_id",
          url: "/vehicle_package_not_paginated", // For Auto Complete To Search
        },
        {
          keyName: "manufacturing_year",
        },
      ],
      rows: [],
      search: null,
      expandData: null,
    };
  },

  watch: {
    [`$route`]() {
      if (this.$route.query.page) {
        this.paginations.current_page = +this.$route.query.page;
        this.setRows();
      } else {
        this.paginations.current_page = 1;
        this.setRows();
      }
    },
  },
  methods: {
    imageError(index) {
      this.rows[
        index
      ].image = require("@/assets/media/images/Icons/no-data.gif");
    },

    mapItem(data) {
      this.expandData = {
        [this.$t("labels.manufacturing_year")]: data.manufacturing_year,
        [this.$t("labels.license_number")]: data.license_number,
        [this.$t("labels.license_serial_number")]: data.license_serial_number,

        [this.$t("labels.plate_number")]: data.plate_number,

        [this.$t("labels.created_at")]: data.created_at,
      };
    },
    setRows() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: `vehicle`,
        params: {
          page: this.paginations.current_page,
          key_word: this.$route.query.keyword,
          model_id: this.$route.query.model_id,
          vehicle_package_id: this.$route.query.package_id,
          manufacturing_year: this.$route.query.year,
          is_active: this.$route.query.is_active,
          is_locked: this.$route.query.is_locked,
        },
      })
        .then((res) => {
          this.loading = false;
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.total = res.data.meta?.total;
          this.rows = res.data.data;
          this.statistics = [];
          for (const [key, value] of Object.entries(res.data.statistics)) {
            this.statistics.push({
              title: this.$t(key),
              count: value,
              route:
                key == "active_vehicle_count"
                  ? "/vehicles/show-all?is_active=1"
                  : key == "deactivate_vehicle_count"
                  ? "/vehicles/show-all?is_active=0"
                  : key == "vehicles_count"
                  ? "/vehicles/show-all"
                  : "/vehicles/show-all?is_locked=1",
            });
          }
        })
        .catch((err) => {
          // console.log(err.response.data.messages);
          let message =
            err.response?.data.message ?? err.response?.data.messages;
          this.$iziToast.error({
            title: this.$t("validation.error"),
            message: message,
          });
          this.loading = false;
        });
    },
    clickRow(rowData) {
      if (this.noExpand) return;
      const indexExpanded = this.expanded.findIndex((i) => i === rowData);
      if (indexExpanded > -1) {
        // Exisit
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded = [];
        this.expanded.push(rowData);
        this.mapItem(rowData);
        // this.expandData = rowData
      }
    },
    showItem(item) {
      this.$router.push({ path: `/vehicles/show/` + item.id });
    },
    editItem(item) {
      this.$router.push({ path: `/vehicles/edit/` + item.id });
    },
    fetchData(e) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: e }),
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setRows();
    },

    changeActiveStatus(item) {
      this.button_.id = item.id;
      this.axios
        .post(`vehicle/${item.id}/update-is_active`)
        .then(() => {
          this.button_.id = "";
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message:
              item.is_active == 1
                ? "تم تفعيل السيارة"
                : "تم الغاء تفعيل السيارة",
          });
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
    changeLockStatus(item) {
      this.button_.id = item.id;
      this.axios
        .post(`vehicle/${item.id}/update-is_lock`)
        .then(() => {
          this.button_.id = "";
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message:
              item.is_locked == 1 ? "تم حجب السيارة" : "تم الغاء حجب السيارة",
          });
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
    changeTripStatus(item) {
      this.button_.id = item.id;
      this.axios
        .post(`vehicle/${item.id}/update-is_in_order`)
        .then(() => {
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message:
              item.is_in_order == 1
                ? "تم وضع السياره في رحلة"
                : "تم الغاء الرحلة ",
          });
          this.button_.id = "";
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },

  created() {
    this.canEdit = this.$permission.allowed("vehicles/edit/");
    this.canAdd = this.$permission.allowed("vehicles/add");
    this.canDelete = this.$permission.allowed("vehicles/delete");
    this.canShow = this.$permission.allowed("vehicles/show/");
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>
